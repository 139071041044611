<template>
  <div
    class="Home"
    style="
      height: 100%;
      width: 100%;
      padding-left: 0px;
      padding-top: 0px;
      margin-top: 0px;
      margin-left: 0px;
    "
  >
    <el-container style="height: 100%; width: 100%">
      <el-header
        style="
          width: 100%;
          padding-left: 0px;
          padding-top: 0;
          padding-right: 0px !important;
          height: 56px;
          color: #ffffff;
          background-color: #0061f0;
          display: flex;
          justify-content: space-between;
        "
      >
        <div style="display: flex;border-bottom: 1px solid #dcdfe6;align-items: center;width: 200px;padding-left: 20px;cursor: pointer;" @click="goHome">
            <!--img :src="iasmIcon" class="img-icon" /-->
            <img src="@/assets/image/home/logo.png" />
            <span class="menu-title">数据开放平台</span>
        </div>
        <el-menu
          class="el-menu-class"
          mode="horizontal"
          background-color="#0061f0"
          :ellipsis="true"
          :default-active="routerData.path"
          text-color="#ffffff"
          router="router"
          style="width: calc(100% - 750px)"
        >
          <!-- <el-menu-item index="/home/ApiMarket" class="menu-title">
            <img src="@/assets/image/home/logo.png" />
            <span>数据开放平台</span>
          </el-menu-item> -->
          <!-- <div class="flex-grow1" /> -->
          
          <div v-for="menu in menuList.list" :key="menu">
            <el-menu-item 
            v-if="!menu.children.length"
            :index="menu.path"
            popper-class="multistage"
            class="menu-font"
            >
               <el-icon><component :is="menu.meta.icon"/></el-icon>
               <span style="font-size: 16px">{{menu.meta.title}}</span>
            </el-menu-item>
            <el-sub-menu
             v-else
            :index="menu.path"
            popper-class="multistage"
            
            class="menu-font"
            >
              <template #title>
              <el-icon><component :is="menu.meta.icon" /></el-icon>
              <span style="font-size: 16px;">{{menu.meta.title}}</span>
             </template>
             <el-menu-item
               v-for="menuChild in menu.children" :key="menuChild"
              id="multistage-class"
              :index="menuChild.path"
              >
              <el-icon><component :is="menuChild.meta.icon"/></el-icon>
              {{menuChild.meta.title}}
              </el-menu-item>
             
            </el-sub-menu>
          </div>
         
        </el-menu>

        <el-menu
          class="el-menu-class"
          mode="horizontal"
          style="width: 545px"
          background-color="#0061f0"
          :ellipsis="false"
          :default-active="routerData.path"
          text-color="#ffffff"
          router="router"
        >
          <!-- <div class="flex-grow1" /> -->
          <!-- <el-menu-item index="6">{{ tenantName }}</el-menu-item> -->
           <div  class="jsc-box">
            <!--img :src="iasmIcon" class="img-icon" /-->
            <img src="@/assets/image/cockpit/jsc.png" style="margin:10px 5px 0 0;cursor: pointer;" @click='goCockpit'/>
          </div>
          <el-select
            v-model="data.appId"
            placeholder="请选择应用"
            style="width: 8%; margin-top: 13px; width: 120px"
            @change="switchApp"
            popper-class="selectStyle"
            class="right-header-select"
          >
            <el-option
              v-for="item in appInfoList.list"
              :key="item.appId"
              :label="item.appName"
              :value="item.appId"
            />
          </el-select>
          &nbsp;&nbsp;
          <el-select
            v-model="data.roleId"
            placeholder="请选择角色"
            style="width: 8%; margin: 13px 20px 0 0; width: 120px"
            @change="switchRoles"
            popper-class="selectStyle"
            class="right-header-select"
          >
            <el-option
              v-for="item in roleinfos.list"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            />
          </el-select>
          <!-- <el-sub-menu index="/iasm_base/app/switchApp">
            <template #title><span class="menu-switch">{{ appName }}</span></template>
            <el-menu-item v-for="item in appInfoList.list" :key="item.appId" :index="item.appId + ''" class="menu-switch">
              {{ item.appName }}
            </el-menu-item>
          </el-sub-menu> -->
          <!-- <el-sub-menu index="/iasm_base/role/switchRoles" class="menu-switch">
            <template #title><span class="menu-switch">{{ roleName }}</span></template>
            <el-menu-item v-for="item in roleinfos.list" :key="item.roleId" :index="item.roleId + ''" class="menu-switch">
              {{ item.roleName }}
            </el-menu-item>
          </el-sub-menu> -->
          <el-sub-menu index="/home/UserCenter" popper-class="home-sub-menu">
            <template #title>
              <!-- <span class="menu-switch"><el-icon><UserFilled /></el-icon>{{ userName }}</span> -->
              <span class="menu-switch">
                <el-badge
                  :value="badgeNum"
                  :max="10"
                  class="badge_item"
                  :hidden="!badgeNum || badgeNum === 0"
                >
                  <!-- <el-icon><UserFilled /></el-icon> -->
                  <img
                    src="../assets/image/home/user.png"
                    alt=""
                    style="
                      width: 28px;
                      height: 28px;
                      vertical-align: middle;
                      margin-right: 5px;
                    "
                  />
                </el-badge>
                {{ userName }}
              </span>
            </template>
           
            <el-menu-item
              index="/home/UserCenter/AccountSettings"
              class="menu-switch"
              ><span>账号设置</span></el-menu-item
            >
            <el-menu-item
              @click="logout()"
              class="menu-switch"
              >退出登录</el-menu-item
            >
          </el-sub-menu>
        </el-menu>
      </el-header>
      <el-main
        class="main common-layout"
        style="
          height: calc(100% - 56px);
          width: 100%;
          padding-left: 0px;
          padding-top: 0px;
          padding-right: 0px;
          padding-bottom: 0px;
          overflow-y: auto;
        "
        >
        <div style="height:25px;line-height:25px;padding-left:28px;display:flex;align-items:center;">
          <el-breadcrumb separator="/">
              <el-breadcrumb-item v-for="bread in breadCrumb" :to="{ path:bread.path}" :key="bread.path">{{bread.name}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <router-view></router-view>
         <AI />
        </el-main>
    </el-container>
     
  </div>
</template>
<script>
// @ is an alias to /src

import { formatterMenuList } from '@/utils/function'
import {request} from '@/api'
import { ref, reactive,onMounted, computed} from "vue";
import router from "@/router";
import iasmIcon from "@/assets/image/iasm.png";
import { useStore } from "vuex";
import AI from '@/components/common/AI'
import {useRoute} from 'vue-router'
import {breadCrumbPath} from '@/utils/breadCrumbPath'

const isProduct = false;
export default {
  components:{
    AI
  },
  computed: {
    badgeNum() {
      const store = useStore();
      // store.dispatch('updateWaitForCount')
      return store.state.waitForCount;
    },
  },

  name: `Home`,
  setup() {
    let storeInfo = useStore();
    let route = useRoute()
    
    const breadCrumb = computed(() => {
      const routeName = (route.name || '').toString()
      if (routeName && breadCrumbPath[routeName]) {
        return breadCrumbPath[routeName].zh_CN
      } else {
        return []
      }
    })

    let menuList = reactive({
       list: [],
    });
    
    const changeApp = ref(null);
    const userId = ref(localStorage.getItem("userId"));
    const userName = ref(localStorage.getItem("userName"));
    const tenantName = ref(localStorage.getItem("tenantName"));
    const roleName = ref(localStorage.getItem("currentRoleName"));
    const appName = ref(localStorage.getItem("appName"));
    const data = reactive({
      roleId: null,
      appId: null,
    });
    
    

    const getData = () => {
      data.appId = parseInt(localStorage.getItem("appId"));
      data.roleId = parseInt(localStorage.getItem("currentRoleId"));
    };
    const appInfoList = reactive({
      list: [],
    });
    const roleinfos = reactive({
      list: [],
    });
    const routerData = reactive({
      //路径
      path: "",
    });
    const setRouterPath = () => {
      let routerPath = window.location.hash;
      let pathArr = routerPath.split("/");
      if (pathArr[2] == "UserCenter") {
        routerData.path =
          "/" +
          pathArr[1] +
          "/" +
          pathArr[2] +
          "/" +
          pathArr[3] +
          "/" +
          pathArr[4];
      } else {
        routerData.path = "/" + pathArr[1] + "/" + pathArr[2];
      }
      // routerData.path = this.$route.path;
      // debugger
    };
    const logout = () => {
      console.log("userId:" + userId.value);
      request.logout({userId: userId.value}).then((resp) => {
        resp.data = 1;
        if (isProduct) {
          location.href = "http://portal.nanniwan.online/#/login";
        } else {
          router.replace({
              path: '/login',
            })
        }
        localStorage.clear()
      });
    };
    const getAppInfoList = () => {
      request.selectUserAppInfos({
          userId: userId.value,
          tenantId:-1
        }).then((res) => {
        appInfoList.list = res.data.data.result;
      });
    };
    const getRolesInfo = () => {
      request.selectRolesByUserId({userId: userId.value}).then((resp) => {
        roleinfos.list = resp.data.data;
      });
    };
    const switchApp = () => {
      let realAppName = appName.value;
      request.switchApp({
          userId: userId.value,
          appId: data.appId,
        }).then((resp) => {
        resp.data = 1;
        localStorage.setItem("appId", data.appId);
        localStorage.setItem("appName", realAppName);
        window.location.reload();
      });
    };
    const switchRoles = () => {
      request.switchRoles({
          userId: userId.value,
          roleId: data.roleId,
        }).then((resp) => {
        for (let i = 0; i < roleinfos.list.length; i++) {
          if (roleinfos.list[i].roleId == data.roleId) {
            roleName.value = roleinfos.list[i].roleName;
            localStorage.setItem("currentRoleId", data.roleId);
            localStorage.setItem("currentRoleName", roleName.value);
            localStorage.setItem("AuthCodeList", resp.data.data);
            // router.push('/home/homeView');
            location.href = "/#/home/ApiMarket/ApiMarketList";
            location.reload(true);
            break;
          }
        }
      });
    };
    const goHome = () => {
      location.href = "/#/home/ApiMarket/ApiMarketList"
      location.reload(true);
    }
  const goCockpit=()=>{
     router.push('/Cockpit');
  }
  
  onMounted(async ()=>{
    let list = storeInfo.state.menuList
    if(list && list.length > 0){
      menuList.list = formatterMenuList(list)
    }else{
      let roleId = parseInt(localStorage.getItem("currentRoleId"));
      let res = await request.getMenuListByRoleId({roleId,projectId:1})
      menuList.list = formatterMenuList(res.data.data)
    }

  })
    return {
      breadCrumb,
      menuList,
      data,
      userId,
      userName,
      tenantName,
      roleName,
      roleinfos,
      appName,
      appInfoList,
      logout,
      getRolesInfo,
      switchApp,
      switchRoles,
      getAppInfoList,
      routerData,
      setRouterPath,
      iasmIcon,
      changeApp,
      router,
      getData,
      goHome,
      goCockpit
    };
  },
  beforeCreate() {
    this.getRolesInfo();
    this.getAppInfoList();
    this.getData();
    this.setRouterPath();
  },
};
</script>
<style lang="scss">
.el-header,
.el-footer {
  background-color: #f4f6fb;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  text-align: center;
  line-height: 200px;
  background-color: #f4f6fb;
}

.el-main {
  // background-color: #e9eef3;
  color: #333;
  text-align: center;
  overflow-x: hidden;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

/* LOGO位移 */
.div-logo {
  position: absolute;
  left: 30px;
  top: 25px;
  z-index: 100;
  width: 100px;
  height: 80px;
}

.flex-grow1 {
  flex-grow: 1;
}

.right-header-select {
  width: 120px;
  font-size: 12px;
  .el-input__wrapper {
    border-radius: 50px;
    width: 120px;
    background-color: transparent;
    .el-input__inner {
      color: white;
      font-size: 12px;
    }
  }
}
.img-icon {
  display: block;
  width: 200px;
  height: 55px;
}

.el-menu-class {
  height: 101%;
  width: 100%;
}

// .el-menu-item {
//   font-size: 20px !important;
// }

// .el-menu-item.is-active {
//   color: #1f7cff !important;
// }

.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff !important;
}
.multistage {
  border-radius: 4px;
  border: none !important;
  .el-menu {
    background: #fff;
    border-radius: 4px;
  }
  .el-sub-menu__icon-arrow{
    color:#fff!important;
  }
  #multistage-class {
    background: #fff;
    color: #091328;
    border-radius: 4px;
    margin: 0 4px;
    &:hover,
    .is-opened:hover {
      color: #0067ff;
      background: #0067ff10;
    }
  }
  #multistage-class.is-active {
    color: #0067ff !important;
    background: #0067ff10 !important;
  }
}
</style>

